<template>
  <div>
    <JayaTitle classType="page-title" titleName="Cultures associées"/>
    <div class="d-flex justify-content-center">
      <b-button
          v-if="plantIdList.length === 0"
          class="mt-4"
          variant="primary"
          @click="$refs.addPlantPopUp.openPopUp()">
        <feather-icon
            icon="PlusIcon"
            size="16"/>
        <span class="align-middle ml-50">Ajouter une plante</span>
      </b-button>
      <div v-else>
        <VGardenInteractions :key="interactionsKey" :plantList="plantInteractionsList" :removePlants="removePlants" :vGarden="{vgarden_plants: plantInteractionsList}" style="margin-bottom: 12rem !important;" @selectedPlant="openRemovePlantPrompt"/>

        <div class="fixed-bottom d-flex justify-content-center">
          <div class="max-w-xs">
            <div class="d-flex flex-wrap justify-content-around">
              <b-button
                  v-if="!removePlants"
                  class="mb-50"
                  variant="dark"
                  @click="getPlantAdvice">
                <feather-icon
                    icon="ArrowRightIcon"
                    size="16"/>
                <span class="align-middle ml-50">Conseils d'associations</span>
              </b-button>
              <b-button
                  v-else
                  class="mb-50"
                  variant="danger"
                  @click="resetPrompt = true">
                <feather-icon
                    icon="TrashIcon"
                    size="16"/>
                <span class="align-middle ml-50">Tout retirer</span>
              </b-button>
            </div>

            <div class="d-flex flex-wrap justify-content-around">
              <b-button
                  v-if="!removePlants"
                  class="mb-1"
                  variant="primary"
                  @click="getPlants">
                <feather-icon
                    icon="AddIcon"
                    size="16"/>
                <span class="align-middle ml-50">Ajouter</span>
              </b-button>
              <b-button
                  :variant="removePlants ? 'outline-danger' : 'danger'"
                  class="mb-1"
                  @click="removePlants=!removePlants">
                <feather-icon
                    v-if="!removePlants"
                    icon="TrashIcon"
                    size="16"/>
                <span class="align-middle ml-50">{{ removePlants ? 'Annuler' : 'Retirer' }}</span>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <JayaPopUp
        ref="addPlantPopUp"
        name="ajout-plante"
        title="Ajouter a la liste ?"
        @scroll-bottom="getAdvice ? '' : $refs.plantSearch.loadNext()">
      <PlantSearch
          v-if="!getAdvice"
          ref="plantSearch"
          :activeFilter="false"
          :vGPlantOnly="true"
          class="max-w-xl"
          clickToGiveId
          @newSeleciton="openAddPlantPrompt"/>
      <div v-else>
        <JayaTitle class="mb-3" classType="page-title" titleName="Associations positives"/>
        <p class="w-full text-center mb-3"> Les plantes qui s'associent le mieux avec votre liste </p>
        <PlantCard
            v-for="plant in plantAdviceList"
            :key="plant.id"
            :plant-photos="plant.photos"
            :plant-pictos="plant.pictos"
            :plantId="plant.id"
            :subtitle="plant.scientific_name"
            :title="plant.common_name"
            clickToGiveId
            v-on:cardClicked="openAddPlantPrompt(plant)"/>
      </div>
      <b-modal
          v-model="addPlantPrompt"
          cancel-text="Annuler"
          centered
          ok-text="Ajouter"
          title="Ajouter la plante ?"
          @cancel="addPlantPrompt = false"
          @ok="addPlant">
        <h3 class="text-center"> {{ activePlant.common_name }} </h3>
      </b-modal>
    </JayaPopUp>

    <b-modal
        v-model="removePlantPrompt"
        cancel-text="Annuler"
        centered
        ok-text="Retirer"
        title="Retirer la plante ?"
        variant="danger"
        @cancel="cancelRemovePlant"
        @ok="removePlant">
      <h3 class="text-center"> {{ activePlant.common_name }} </h3>
    </b-modal>

    <b-modal
        v-model="resetPrompt"
        cancel-text="Annuler"
        centered
        ok-text="Tout retirer"
        title="Retirer toutes les plantes ?"
        variant="danger"
        @cancel="resetPrompt = false"
        @ok="restInteractions"/>
  </div>
</template>

<script>
import JayaTitle from '@/components/global/JayaTitle.vue'
import VGardenInteractions from '@/components/vgarden/VGardenInteractions.vue'
import JayaPopUp from '@/components/global/JayaPopUp.vue'
import PlantSearch from "@/components/plants/PlantSearch.vue"
import PlantCard from "@/components/plants/PlantCard.vue"
import {BButton, BModal} from "bootstrap-vue";

export default {
  name: 'InteractionsExplorer',
  components: {
    JayaTitle,
    VGardenInteractions,
    JayaPopUp,
    PlantSearch,
    PlantCard,
    BButton,
    BModal,
  },
  data() {
    return {
      addPlantPrompt: false,
      addPlantToList: false,
      removePlantPrompt: false,
      resetPrompt: false,
      removePlants: false,
      activePlant: {},
      plantIdList: [],
      plantInteractionsList: [],
      interactionsKey: 1,
      getAdvice: false,
      plantAdviceList: [],
    }
  },
  mounted() {
    if (!localStorage.interactions_module_id_list) {
      localStorage.setItem('interactions_module_id_list', JSON.stringify([]))
    } else {
      this.plantIdList = JSON.parse(localStorage.interactions_module_id_list)
    }
    if (!localStorage.interactions_module_plant_list) {
      localStorage.setItem('interactions_module_plant_list', JSON.stringify([]))
    } else {
      this.plantInteractionsList = JSON.parse(localStorage.interactions_module_plant_list)
    }
    this.getInteractions()
  },
  methods: {
    getPlants() {
      this.getAdvice = false
      this.$refs.addPlantPopUp.openPopUp()
    },
    getPlantAdvice() {
      this.$loading(true)
      this.getAdvice = true
      this.$store.dispatch("plant/searchAdvice", {
        limit: 20,
        plant_ids: this.plantIdList,
        specy_only: true
      })
          .then((response) => {
            this.plantAdviceList = response.data.results
            this.$loading(false)
          })
          .catch(() => {
            this.plantAdviceList = []
            this.$loading(false)
          })
      this.$refs.addPlantPopUp.openPopUp()
    },
    openAddPlantPrompt(value) {
      if (this.plantIdList.includes(value.id)) {
        this.$notify({
          title: "Cette plante est déjà dans la liste",
          color: "danger",
          time: 4000
        })
      } else {
        this.activePlant = value
        this.addPlantPrompt = true
      }
    },
    addPlant() {
      this.plantIdList.push(this.activePlant.id)
      localStorage.setItem('interactions_module_id_list', JSON.stringify(this.plantIdList))
      this.activePlant = {}
      this.$refs.addPlantPopUp.closePopUp()
      this.getInteractions()
    },
    openRemovePlantPrompt(value) {
      this.activePlant = value
      this.removePlantPrompt = true
    },
    removePlant() {
      this.plantIdList = this.plantIdList.filter(id => id !== this.activePlant.id)
      this.activePlant = {}
      localStorage.setItem('interactions_module_id_list', JSON.stringify(this.plantIdList))
      this.removePlants = false
      this.getInteractions()
    },
    cancelRemovePlant() {
      this.removePlants = false
      this.removePlantPrompt = false
    },
    restInteractions() {
      this.plantIdList = []
      localStorage.setItem('interactions_module_id_list', JSON.stringify([]))
      this.plantInteractionsList = []
      localStorage.setItem('interactions_module_plant_list', JSON.stringify([]))
      this.getAdvice = false
      this.removePlants = false
    },
    getInteractions() {
      if (this.plantIdList.length > 0) {
        let payload = {
          plant_ids: this.plantIdList,
          inter: true,
        }
        this.$store.dispatch("plant/search", payload)
            .then(response => {
              this.plantInteractionsList = []
              for (const plant of response.data.results) {
                this.plantInteractionsList.push({
                  id: this.plantInteractionsList.length + 1,
                  garden_plant: {plant: plant}
                })
              }
              localStorage.setItem('interactions_module_plant_list', JSON.stringify(this.plantInteractionsList))
              this.interactionsKey = this.interactionsKey + 1
            })
            .catch(() => {
              this.$notify({
                title: "Erreur",
                text: "Une erreur est survenue lors de la recherche d'associations, veuillez réessayer plus tard",
                color: "danger",
                time: 4000
              })
            })
      } else {
        this.restInteractions()
      }
    },
  }
}
</script>
